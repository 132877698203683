import React, { useEffect, useState } from "react"
import { initializeHubspotForm } from "../../../utils/gatsby-helpers"
import { redirectToThankYou } from "../../../utils/thank-you-access";

const SectionFormDemo = ({ lang = "en", form }) => {
    const [formLoaded, setFormLoaded] = useState(false);

    const formLang = {
        "en": {
            title: "Request a demo",
            subtitle: "Let our experts show you how to become a leader",
            region: "na1",
            portalId: "20249583",
            formId: "7c9da40e-3982-4492-a64c-a28d10d027f8"
        },
        "es": {
            title: "Solicita una demostración",
            subtitle: "Descubre con nuestros expertos cómo transformar para liderar",
            region: "na1",
            portalId: "20249583",
            formId: "e5ff4897-1687-48f4-aad2-50cfb0252cb4"
        }
    }

    const thankYouUrl = lang === "es" ? "solicitud-demo" : "request-demo"

    if (!form) {
        form = formLang[lang]
    } else {
        form.portalId = form?.portal_id
        form.formId = form?.form_id
    }
    useEffect(() => {
        if (!formLoaded) {
            initializeHubspotForm(form.portalId, form.formId, "#demo-form-container", form.region, null, () => redirectToThankYou(lang, thankYouUrl))
            setFormLoaded(true);
        }
    }, []);

    return (
        <div id="demo-form-container" className="form-hb" />
    )
}

export default SectionFormDemo
